<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <router-view />
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "DQMPeduli", // Default title
      meta: [{ name: "description", content: "Official website of DQMPeduli" }],
    };
  },
};
</script>

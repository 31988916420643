<template>
  <div class="social-share" style="cursor: pointer">
    <label class="ps-3">Bagikan </label>
    <ul class="social-icon">
      <li class="me-2">
        <a :href="whatsappShareUrl" target="_blank">
          <i class="fab fa-whatsapp"></i>
        </a>
      </li>

      <li class="me-2">
        <a :href="facebookShareUrl" target="_blank">
          <i class="fab fa-facebook-f"></i>
        </a>
      </li>

      <li class="me-2">
        <a :href="twitterShareUrl" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
      </li>

      <li>
        <a :href="pinterestShareUrl" target="_blank">
          <i class="fab fa-pinterest-p"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageUrl: window.location.href, // URL halaman saat ini
      pageTitle: document.title, // Judul halaman
      pageDescription: "DQMPeduli", // Deskripsi untuk berbagi
      pageImage: "@/assets/images/landing/market/fundraiser.png", // Gambar untuk Pinterest
    };
  },
  computed: {
    whatsappShareUrl() {
      return `https://wa.me/?text=${encodeURIComponent(
        `${this.pageTitle} - ${this.pageUrl}`
      )}`;
    },
    facebookShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.pageUrl
      )}`;
    },
    twitterShareUrl() {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.pageUrl
      )}&text=${encodeURIComponent(this.pageTitle)}`;
    },
    pinterestShareUrl() {
      return `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        this.pageUrl
      )}&media=${encodeURIComponent(this.pageImage)}&description=${encodeURIComponent(
        this.pageDescription
      )}`;
    },
  },
};
</script>

<style>
.social-share {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #d0a32a;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.social-share li {
  display: inline-block;
}
.social-icon {
  list-style: none;
  padding: 10px;
  margin: 1px;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.social-share .social-icon {
  margin-left: auto;
}

.soc .social-share a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 50px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.social-share a:hover {
  background-color: #d0a32a;
  color: #fff;
}
.social-share {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
}
.social-share li {
  display: inline-block;
}
.social-share a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.social-share a:hover {
  background-color: #d0a32a;
  color: #fff;
}
</style>

<template>
  <section class="bg-error d-flex justify-content-center align-items-center">
    <div class="py-5 text-center">
      <div class="appie-error-area">
        <div class="container">
          <div class="col-lg-12">
            <div class="appie-error-content">
              <span style="margin-bottom: -50px; font-size: 100px">404</span>
              <span style="color: #fff !important">Mohon maaf !</span>
              <h4 class="title" style="color: #fff !important">
                Halaman yang sedang Anda cari tidak tersedia
              </h4>

              <router-link to="/" class="main-btn" size="sm"
                ><i class="fal fa-home me-2"></i>Kembali Ke Beranda</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      sidebar: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

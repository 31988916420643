<template>
  <!--====== APPIE SHOWCASE PART START ======-->

  <section class="appie-showcase-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">Berita Terbaru</h3>
            <p>
              Berani berbuat baik, karena kebaikan itu akan kembali kepadamu suatu saat
              nanti
            </p>
          </div>
        </div>
      </div>

      <!-- Loading Spinner -->
      <div v-if="loading" class="row">
        <div class="col-lg-12 text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>

      <!-- Error Message -->
      <div v-else-if="hasError" class="row">
        <div class="col-lg-12 text-center py-5">
          <h3 class="text-dqm">Mohon maaf!</h3>
          <span>Terjadi kesalahan saat memuat data.</span>
        </div>
      </div>

      <!-- No Data Message -->
      <div v-else-if="!loading && noData" class="row">
        <div class="col-lg-12 text-center py-5">
          <h3 class="text-dqm">Mohon maaf!</h3>
          <span>Data Tidak Tersedia</span>
        </div>
      </div>

      <!-- Posts -->
      <div v-else>
        <div class="row">
          <div v-for="(post, index) in posts" :key="index" class="col-lg-4 mb-4">
            <div class="post-item-1" style="height: 100%">
              <router-link :to="`/detail-berita/${post.id}/${post.slug}`">
                <img :src="getImageUrl(post)" alt="Post Image" class="img-berita" />
                <div class="b-post-details">
                  <div class="bp-meta">
                    <a href="#"
                      ><i class="fal fa-clock"></i>{{ formatDate(post.date_gmt) }}</a
                    >
                  </div>
                  <h5>
                    {{ post.title.rendered }}
                  </h5>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <b-button block href="/berita" class="btn-dqm mt-5"
                >Lihat Semua Berita<i class="fal fa-arrow-right ms-2"></i
              ></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE SHOWCASE PART ENDS ======-->
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      posts: [],
      images: {}, // Store images keyed by post ID
      loading: true,
      hasError: false,
      noData: false,
    };
  },
  methods: {
    fetchPosts() {
      this.loading = true;
      this.hasError = false;
      this.noData = false;

      axios
        .get(
          process.env.VUE_APP_BERITAURL +
            "/wordpressapi/wp/v2/posts?_fields=date_gmt,excerpt,title,link,slug,id&categories=1&20&tags=30420&per_page=3&page=1"
        )
        .then((response) => {
          this.posts = response.data;
          this.noData = this.posts.length === 0;
          this.posts.forEach((post) => {
            this.fetchImageForPost(post.id);
          });
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchImageForPost(postId) {
      axios
        .get(
          `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/media?_fields=id,guid,media_type&parent=${postId}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            const updatedData = response.data.map((item) => {
              if (item.guid?.rendered) {
                item.guid.rendered = item.guid.rendered.replace(
                  "admin.darulquran.sch.id",
                  "api-2-gtw.dq.akses.live/wp-image"
                );
              }
              return item;
            });

            this.$set(this.images, postId, updatedData[0].guid.rendered);
          }
        })
        .catch((error) => {
          console.error("Error fetching image for post ID:", postId, error);
        });
    },
    getImageUrl(post) {
      return this.images[post.id] || require("@/assets/images/blog/no-image.png");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  mounted() {
    this.fetchPosts();
  },
};
</script>

<style></style>

<template>
  <div class="pt-5 pb-100">
    <div class="container">
      <div class="col-lg-12">
        <div class="shop-details-box">
          <!-- Tabs Navigation -->
          <div>
            <b-tabs content-class="mt-3" fill>
              <b-tab title="Deskripsi" active> {{ deskripsi }} </b-tab>
              <b-tab title="Donatur">
                <div>
                  <!-- Search and Export -->
                  <div class="row mb-3 pt-2">
                    <div class="col-lg-12 col-md-12">
                      <input
                        type="text"
                        v-model="searchQuery"
                        class="form-control"
                        placeholder="Cari..."
                      />
                    </div>
                  </div>

                  <section class="appie-blog-3-area" v-if="paginationProduks.length > 0">
                    <div class="row">
                      <div
                        class="col-lg-12"
                        v-for="settlement in paginationProduks"
                        :key="settlement.id"
                      >
                        <div style="border: 1px solid #efefef"></div>
                        <div class="mt-10" style="background: #fff; padding-bottom: 12px">
                          <div class="content ms-2">
                            <span style="font-size: 14px; font-weight: bold">
                              {{ settlement.customer_name }}
                            </span>
                            <div class="meta-item">
                              <ul>
                                <li
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span style="font-size: 10px">
                                    <i class="fal fa-clock"></i>
                                    {{ formatCurrency(settlement.price) }}
                                  </span>
                                  <span style="font-size: 10px">
                                    <i class="fal fa-comments"></i>
                                    {{ formatDate(settlement.updated_at) }}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div v-else class="d-flex justify-content-center">TIDAK ADA DATA</div>
                  <div class="mt-2 mb-2">Total Keseluruhan Data: {{ totalProduk }}</div>

                  <div class="row">
                    <div class="col-lg-12">
                      <b-pagination
                        class="text-danger"
                        v-model="currentPage"
                        :total-rows="totalProduk"
                        :per-page="perPage"
                      ></b-pagination>
                    </div>
                  </div>

                  <!-- Table -->

                  <!-- Pagination -->
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    deskripsi: {
      type: String,
    },
    productid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      produkDonasi: { data: [] },
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
      searchQuery: "",
      sortByColumn: "",
      sortDirection: "asc",
    };
  },
  computed: {
    filteredProducts() {
      let filtered = this.produkDonasi.data;

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter((product) =>
          product.customer_name.toLowerCase().includes(query)
        );
      }

      return filtered;
    },
    totalProduk() {
      return this.filteredProducts.length;
    },
    paginationProduks() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredProducts.slice(start, end);
    },
  },
  watch: {
    productid: "fetchProducts",
  },
  methods: {
    formatCurrency(value) {
      if (typeof value !== "number") {
        value = parseFloat(value); // Pastikan nilainya angka
      }
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0, // Hilangkan desimal jika tidak diperlukan
      }).format(value);
    },
    formatDate(dateString) {
      // Mengonversi tanggal ke format Indonesia
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("id-ID", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }).format(date);
    },
    sortBy(column) {
      if (this.sortByColumn === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortByColumn = column;
        this.sortDirection = "asc";
      }

      this.produkDonasi.data.sort((a, b) => {
        let modifier = this.sortDirection === "asc" ? 1 : -1;
        if (a[column] < b[column]) return -1 * modifier;
        if (a[column] > b[column]) return 1 * modifier;
        return 0;
      });
    },
    fetchProducts() {
      const apiUrl = process.env.VUE_APP_SHOPURL;
      axios
        .get(`${apiUrl}/api/transaction/summary?productid=${this.productid}`)
        .then((response) => {
          if (response.data.status === 200) {
            this.produkDonasi.data = response.data.data.settlement;
          }
        })
        .catch((error) => {
          console.error("Error fetching settlement data:", error);
        });
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

<style>
/* Add styles here if needed */
</style>

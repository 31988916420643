<template>
  <div>
    <div v-if="!isLoading">
      <section class="blogpage-section">
        <div class="container">
          <b-breadcrumb style="font-size: 12px">
            <b-breadcrumb-item class="ahref" to="/campaign">
              <b-icon
                icon="newspaper"
                class="me-1"
                scale="1.25"
                shift-v="1.25"
                aria-hidden="true"
              ></b-icon>
              Donasi
            </b-breadcrumb-item>

            <b-breadcrumb-item active>{{
              product.product.slice(0, 30) + "..."
            }}</b-breadcrumb-item>
          </b-breadcrumb>
          <div class="row mt-3">
            <div class="col-lg-8 col-md-12">
              <detail-campaign :gambar="product.url" :judul="product.product" />

              <social-share />

              <info-wakaf :deskripsi="product.desc" :productid="product.id" />
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="position-sticky" style="top: 3rem">
                <side-campaign
                  :totaltarget="product.target"
                  :totalterkumpul="product.total"
                  :idpro="product.id"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <div class="py-5 text-center">
        <div class="container">
          <div class="col-lg-12">
            <b-spinner label="Loading..." variant="warning"></b-spinner>
            <h3 class="text-dqm"></h3>
            <span>Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SideCampaign from "@/components/Campaign/SideCampaign.vue";
import DetailCampaign from "@/components/Campaign/DetailCampaign.vue";
import InfoWakaf from "@/components/Campaign/InfoWakaf.vue";
import SocialShare from "@/components/socialshare.vue";

export default {
  components: {
    SideCampaign,
    InfoWakaf,
    SocialShare,
    DetailCampaign,
  },
  data() {
    return {
      value: 100,
      max: 100,
      product: null,
      donationAmount: 0,
      isLoading: true,
      shopAPI: process.env.VUE_APP_SHOPURL,
    };
  },
  metaInfo() {
    return {
      title: this.product.product,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.product.product,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.product.desc,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.product.url,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
      ],
    };
  },
  mounted() {
    this.proid = this.$route.query.proid;
    if (this.proid) {
      this.fetchProduct(this.proid);
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods: {
    async fetchProduct(proid) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_SHOPURL + `/api/product?productid=${proid}`
        );
        this.product = response.data.data[0];

        // Refresh meta tags after product data is fetched
        this.$nextTick(() => {
          this.$meta().refresh();
        });
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    },
  },
};
</script>

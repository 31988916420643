<template>
  <div>
    <title-sedekah />
    <kalkulator-sedekah />
    <detail-sedekah />
  </div>
</template>

<script>
import TitleSedekah from "./TitleSedekah.vue";
import DetailSedekah from "./DetailSedekah.vue";
import KalkulatorSedekah from "./KalkulatorSedekah.vue";

export default {
  components: {
    TitleSedekah,
    DetailSedekah,
    KalkulatorSedekah,
  },
  metaInfo() {
    return {
      title: "Sedekah",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: "Sedekah",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Melalui DQMPeduli Sedekah adalah mengeluarkan harta yang pokok. mencakup zakat dan non-zakat",
        },
      ],
    };
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import AppHeader from "../components/Layout/Header/Header";
import AppFooter from "../components/Layout/Footer/Footer";

import Landing from '../views/Landing.vue'
import Campaign from '../views/Campaign/Campaign.vue'
import DetailCampaign from '../views/Campaign/DetailCampaign.vue'
import ZakatMaal from '../views/Zakat/Maal/Maal.vue'
import ZakatPenghasilan from '../views/Zakat/Penghasilan/Penghasilan.vue'
import ZakatFidyah from '../views/Zakat/Fidyah/Fidyah.vue'
import ZakatFitrah from '../views/Zakat/Fitrah/Fitrah.vue'
import ZakatPerak from '../views/ComingSoon.vue'
import ZakatEmas from '../views/ComingSoon.vue'
import Sedekah from '../views/Sedekah/Sedekah.vue'
import Wakaf from '../views/ComingSoon.vue'
import DetailWakaf from '../views/ComingSoon.vue'
import Qurban from '../views/ComingSoon.vue'
import DetailQurban from '../views/ComingSoon.vue'
import Berita from '../views/Berita/Berita.vue'
import Kontak from '../views/Kontak/Kontak.vue'
import DetailBerita from '../views/Berita/id/DetailBerita.vue'
import Error from '../views/Error.vue'


import Rekening from '../views/Rekening/index.vue'
import Laporan from '../views/Laporan/index.vue'



// admin
import Login from '../views/Login.vue'
import AppHeaderAdmin from "../components/Admin/Layout/Header/Header";
import AppFooterAdmin from "../components/Admin/Layout/Footer/Footer";

import Dashboard from '../views/Admin/Dashboard.vue'
import DataWakaf from '../views/Admin/Datawakaf/DataWakaf.vue'
import TambahWakaf from '../views/Admin/Datawakaf/Form/FormTambahData.vue'
import DetailWakafAdmin from '../views/Admin/Datawakaf/Form/DetailWakaf.vue'
//Data Donatur
import DonaturSedekah from '../views/Admin/Datadonasi/Sedekah/DonaturSedekah.vue'
import PenghasilanAdmin from '../views/Admin/Datadonasi/Penghasilan/AdminPenghasilan.vue'
import MaalAdmin from '../views/Admin/Datadonasi/Maal/AdminMaal.vue'
import FidyahAdmin from '../views/Admin/Datadonasi/Fidyah/AdminFidyah.vue'
import FitrahAdmin from '../views/Admin/Datadonasi/Fitrah/AdminFitrah.vue'
// end admin 

Vue.use(VueRouter)

const routes = [
  
    {
      path: "/",
      name: "Landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
  },
     {
      path: "/rekening-donasi",
      name: "Rekening",
      components: {
        header: AppHeader,
        default: Rekening,
        footer: AppFooter
      }
    }, {
      path: "/laporan",
      name: "Laporan",
      components: {
        header: AppHeader,
        default: Laporan,
        footer: AppFooter
      }
    },
    {
    path: '/Campaign',
    name: 'Campaign',
    components:
      {
        header: AppHeader,
        default: Campaign,
        footer: AppFooter
      },
    meta:
      {
        breadcrumb: 'Campaign'  
      }
    },
    {
      path: '/detail-campaign',
      components:
      {
        header: AppHeader,
        default: DetailCampaign,
        footer: AppFooter
      },
      name: 'detail-campaign',
    },
      {
    path: '/zakat-maal',
    name: 'Zakat Maal',
      components:
      {
        header: AppHeader,
        default: ZakatMaal,
        footer: AppFooter
      },
    },
    {
    path: '/zakat-penghasilan',
    name: 'Zakat Penghasilan',
    components:
      {
        header: AppHeader,
        default: ZakatPenghasilan,
        footer: AppFooter
      },
    },
    {
    path: '/zakat-fidyah',
    name: 'Zakat Fidyah',
    components:
      {
        header: AppHeader,
        default: ZakatFidyah,
        footer: AppFooter
      },
  },
  {
    path: '/zakat-fitrah',
    name: 'Zakat Fitrah',
    components:
      {
        header: AppHeader,
        default: ZakatFitrah,
        footer: AppFooter
      },
  },
     {
    path: '/zakat-perak',
    name: 'Zakat Perak',
    components:
      {
        header: AppHeader,
        default: ZakatPerak,
        footer: AppFooter
      },
    },
    {
    path: '/zakat-emas',
    name: 'Zakat Emas',
    components:
      {
        header: AppHeader,
        default: ZakatEmas,
        footer: AppFooter
      },
    },
    {
    path: '/sedekah',
    name: 'Sedekah',
    components:
      {
        header: AppHeader,
        default: Sedekah,
        footer: AppFooter
      },
    },
      {
    path: '/wakaf',
    name: 'Wakaf',
    components:
      {
        header: AppHeader,
        default: Wakaf,
        footer: AppFooter
      },
    },
    {
    path: '/detail-wakaf',
    name: 'Detail Wakaf',
    components:
      {
        header: AppHeader,
        default: DetailWakaf,
        footer: AppFooter
      },
    },
    {
    path: '/qurban',
    name: 'Qurban',
    components:
      {
        header: AppHeader,
        default: Qurban,
        footer: AppFooter
      },
    },
    {
    path: '/detail-qurban',
    name: 'Detail Qurban',
    components:
      {
        header: AppHeader,
        default: DetailQurban,
        footer: AppFooter
      },
    },
    {
    path: '/berita',
    name: 'Berita Terbaru',
    components:
      {
        header: AppHeader,
        default: Berita,
        footer: AppFooter
      },
  },

   {
    path: '/detail-berita/:id/:slug',
    name: 'Detail Berita',
    components:
      {
        header: AppHeader,
        default: DetailBerita,
        footer: AppFooter
      },
  },
    
    
  {
    path: '/kontak',
    name: 'Kontak',
    components:
      {
        header: AppHeader,
        default: Kontak,
        footer: AppFooter
      },
    },
   
  { path: "*", component: Error }
  ,
  // admin

  {
    path: '/login',
    name: 'Login',
    components:
      {
        
        default: Login,
       
      },
    },
{
      path: '/dashboard',
      name: 'Dashboard',
      components: {
        header: AppHeaderAdmin,
        default: Dashboard,
        footer: AppFooterAdmin
      },
    meta: { requiresAuth: true },
      
    },
  
  {
    path: '/sedekah-admin',
    name: 'Donatur Sedekah',
    components:
      {
        header: AppHeaderAdmin,
        default: DonaturSedekah,
        footer: AppFooterAdmin
    },
    meta: { requiresAuth: true },
  },

   {
    path: '/penghasilan-admin',
    name: 'Admin Penghasilan',
    components:
      {
        header: AppHeaderAdmin,
        default: PenghasilanAdmin,
        footer: AppFooterAdmin
     },
    meta: { requiresAuth: true },
  },

   {
    path: '/maal-admin',
    name: 'Admin Maal',
    components:
      {
        header: AppHeaderAdmin,
        default: MaalAdmin,
        footer: AppFooterAdmin
     },
    meta: { requiresAuth: true },
  },

   {
    path: '/fidyah-admin',
    name: 'Admin Fidyah',
    components:
      {
        header: AppHeaderAdmin,
        default: FidyahAdmin,
        footer: AppFooterAdmin
     },
    meta: { requiresAuth: true },
  },
  {
    path: '/fitrah-admin',
    name: 'Admin Fitrah',
    components:
      {
        header: AppHeaderAdmin,
        default: FitrahAdmin,
        footer: AppFooterAdmin
    },
    meta: { requiresAuth: true },
  },
   {
    path: '/data-wakaf',
    name: 'Data Wakaf',
    components:
      {
        header: AppHeaderAdmin,
        default: DataWakaf,
        footer: AppFooterAdmin
     },
    meta: { requiresAuth: true },
  },
   
  {
    path: '/tambah-wakaf',
    name: 'Tambah Data Wakaf',
    components:
      {
        header: AppHeaderAdmin,
        default: TambahWakaf,
        footer: AppFooterAdmin
    },
    meta: { requiresAuth: true },
  },
  {
      path: '/detail-wakaf-admin',
      components:
      {
        header: AppHeaderAdmin,
        default: DetailWakafAdmin,
        footer: AppFooterAdmin
      },
    name: 'detail-wakaf-admin',
      meta: { requiresAuth: true },
    },
  // end admin 
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem("isLoggedIn");
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    // Jika halaman membutuhkan login tetapi belum login, redirect ke login
    next("/login");
  } else {
    next(); // Lanjutkan ke halaman tujuan
  }
});

export default router

<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area footer-dqm">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="https://wa.me/6281212427687?"
                ><img
                  src="@/assets/images/logo/footer-logo.png"
                  alt="footer logo"
                  style="height: 70px"
                  target="_blank"
              /></a>
            </div>
            <a href="https://wa.me/6281212427687?" target="_blank">
              Ingin Investasi dunia akhirat?
            </a>
            <p>
              Temukan cara terbaik untuk berinvestasi dunia akhirat bersama DQM Peduli,
              lembaga resmi penyaluran Ziswaf yang mengumpulkan, mengelola, serta
              memanfaatkan zakat, infaq, shadaqah dan wakaf sesuai dengan fungsinya dengan
              berbagai macam cara yang efektif, efisien, dan transparan untuk memberikan
              manfaat sebesar-besarnya bagi ummat.
            </p>

            <!-- <div class="social mt-30">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Donasi</h4>
            <ul>
              <li><router-link to="/rekening-donasi">Rekening Donasi</router-link></li>
              <li>
                <a
                  href="https://wa.me/6281212427687?text=Assalamu%E2%80%99alaikum%20Warahmatullahi%20Wabarakatuh%2C%20hallo%20DQM%20Peduli%20%5Bwebsite"
                  target="_blank"
                  >Konfirmasi Donasi</a
                >
              </li>
              <li><a href="https://izi.or.id/" target="_blank">IZI</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Hubungi Kami</h4>
            <ul>
              <li>
                <a href="mailto:dqmpeduli@gmail.comd" target="_blank"
                  ><b-icon icon="envelope" variant="warning" aria-hidden="true"></b-icon>
                  dqmpeduli@gmail.com</a
                >
              </li>
              <li>
                <a href="https://wa.me/6281212427687" target="_blank"
                  ><b-icon icon="whatsapp" variant="warning" aria-hidden="true"></b-icon>
                  0812 1242 7687</a
                >
              </li>
              <li>
                <a href="https://maps.app.goo.gl/WRURnSkiUmn8f4tP9" target="_blank"
                  ><b-icon icon="pin-map" variant="warning" aria-hidden="true"></b-icon>
                  Darul Quran Mulia, Gunungsindur, Kab. Bogor</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="footer-copyright d-flex align-items-center justify-content-between pt-35"
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <img
                    src="https://izi.or.id/wp-content/uploads/2022/03/logo.png"
                    style="height: 50px"
                  />
                </li>
                <li>
                  <img src="@/assets/images/logo/footer-logo.png" style="height: 50px" />
                </li>
              </ul>
            </div>

            <div class="copyright-text">
              <p>&copy; {{ new Date().getFullYear() }}. DQM Peduli</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="back-to-top back-to-top-3">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>

    <div class="whatsapp whatsapp-2 shadow d-none d-lg-block d-flex align-items-center">
      <a
        href="https://wa.me/6281212427687?text=Assalamu%E2%80%99alaikum%20Warahmatullahi%20Wabarakatuh%2C%20hallo%20DQM%20Peduli%20%5Bwebsite%5D"
        target="_blank"
        ><b-icon
          icon="whatsapp"
          style="color: rgba(0, 0, 0, 0.54)"
          font-scale="1.7"
          class="me-1"
        ></b-icon
        >Ada yang bisa kami bantu ?</a
      >
    </div>

    <div class="wamobile whatsapp-2 shadow d-block d-lg-none">
      <a
        href="https://wa.me/6281212427687?text=Assalamu%E2%80%99alaikum%20Warahmatullahi%20Wabarakatuh%2C%20hallo%20DQM%20Peduli%20%5Bwebsite%5D"
        target="_blank"
        ><b-icon
          icon="whatsapp"
          style="color: rgba(0, 0, 0, 0.54)"
          font-scale="1.3"
        ></b-icon
      ></a>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>

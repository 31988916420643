<template>
  <div>
    <div class="bisylms-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d247.8215054544511!2d106.67999296110132!3d-6.37539317231641!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e5bb96a4311f%3A0xe33559fd09bea58e!2sBERKAH%20GROUP%20HEAD%20OFFICE!5e0!3m2!1sid!2sid!4v1726026496829!5m2!1sid!2sid"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <contact-inputs />
  </div>
</template>

<script>
import ContactInputs from "./ContactInputs.vue";

export default {
  components: { ContactInputs },
  data() {
    return {
      sidebar: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  metaInfo() {
    return {
      title: "Kontak",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: "Kontak",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Informasi Alamat, Kontak DQMPeduli",
        },
      ],
    };
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>

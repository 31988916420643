<template>
  <div>
    <title-maal />
    <kalkulator-maal />
    <detail-maal />
  </div>
</template>

<script>
import TitleMaal from "./TitleMaal.vue";
import DetailMaal from "./DetailMaal.vue";
import KalkulatorMaal from "./KalkulatorMaal.vue";

export default {
  components: {
    TitleMaal,
    DetailMaal,
    KalkulatorMaal,
  },
  metaInfo() {
    return {
      title: "Zakat Maal",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: "Zakat Maal",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            " Zakat yang dikeluarkan individu maupun lembaga atas harta/penghasilan yang diperolehnya dengan syarat dan ketentuan yang sudah ditetapkan. ",
        },
      ],
    };
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <zakat-title />
    <kalkulator-zakat />
    <detail-zakat />
  </div>
</template>

<script>
import ZakatTitle from "./TitleZakat.vue";
import DetailZakat from "./DetailZakat.vue";
import KalkulatorZakat from "./KalkulatorZakat.vue";

export default {
  components: {
    ZakatTitle,
    DetailZakat,
    KalkulatorZakat,
  },
  metaInfo() {
    return {
      title: "Zakat Penghasilan",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: "Zakat Penghasilan",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "  Zakat penghasilan merupakan bagian dari zakat maal dan wajib diberikan atas pendapatan yang dimiliki ",
        },
      ],
    };
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-services-2-area pb-100 pt-90 pb-100",attrs:{"id":"service"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._l((_vm.rekenings),function(rekening){return _c('div',{key:rekening.id,staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-blog-item-3 mt-30"},[_c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":rekening.logo,"alt":"Logo Bank"}})]),_c('div',{staticClass:"content"},[_c('h4',{staticClass:"title"},[_c('a',{attrs:{"href":"#","title":"Klik Untuk Salin"},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard(rekening.number)}}},[_vm._v(_vm._s(rekening.number))])]),_c('div',{staticClass:"meta-item"},[_c('ul',[_c('li',[_c('i',{staticClass:"fal fa-file"}),_vm._v(" "+_vm._s(rekening.description))])])])])])])}),(_vm.showSnackbar)?_c('div',{staticClass:"snackbar"},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]):_vm._e(),_vm._m(1)],2)]),_c('div',{staticClass:"container mt-5"},[_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"service-details-sidebar mr-50"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"service-download-widget"},[_c('a',{attrs:{"href":"https://wa.me/6281212427687?text=Assalamu%E2%80%99alaikum%20Warahmatullahi%20Wabarakatuh%2C%20hallo%20DQM%20Peduli%20%5Bwebsite"}},[_c('b-icon',{staticClass:"me-3",attrs:{"icon":"whatsapp","variant":"dark","aria-hidden":"true"}}),_c('span',[_vm._v("Pembayaran berhasil dan Konfirmasi dengan WA (6281212427687 )")])],1)])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"appie-section-title"},[_c('h3',{staticClass:"appie-title"},[_vm._v("Metode Transfer Bank")]),_c('p',[_vm._v(" Metode Transfer Bank via ATM dan Internet Banking atas nama"),_c('b',[_vm._v(" DQ Peduli")]),_vm._v(" yang tersedia di bawah ini melalui Rekening BSI ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"blog-btn text-center mt-60"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"appie-section-title"},[_c('h3',{staticClass:"appie-title"},[_vm._v("Pembayaran via QRIS")]),_c('p',[_vm._v(" Metode pembayaran melalui QRIS dapat melalui kode QRIS yang tersedia di bawah ini ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-download-widget mb-2"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fal fa-download"}),_c('span',[_vm._v("Bagaimana cara melakukan pembayaran menggunakan QRIS?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-category-widget"},[_c('ul',[_c('li',[_c('i',{staticClass:"fal fa-home"}),_vm._v("Pilih metode pembayaran di halaman proses pembayaran, lalu pilih “Uang elektronik” kemudian “QRIS” dan klik “Bayar sekarang”. ")]),_c('li',[_c('i',{staticClass:"fal fa-tag"}),_vm._v("Selanjutnya kamu akan diarahkan ke halaman konfirmasi nominal transaksi. ")]),_c('li',[_c('i',{staticClass:"fal fa-user-friends"}),_vm._v("Cek kembali nominal transaksi kamu, lalu scan QR Code menggunakan aplikasi uang elektronik, dompet elektronik, atau mobile banking yang mendukung QRIS. ")]),_c('li',[_c('i',{staticClass:"fal fa-bookmark"}),_vm._v("Apabila kamu menggunakan handphone untuk melakukan transaksi, kamu dapat melakukan screencapture dan upload barcode QRIS di aplikasi yang mendukung pembayaran QRIS. ")]),_c('li',[_c('i',{staticClass:"fal fa-globe"}),_vm._v("Masukan PIN untuk melanjutkan transaksi. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4"},[_c('img',{attrs:{"src":require("@/assets/images/rekening/qris.jpeg"),"alt":""}})])
}]

export { render, staticRenderFns }
<template>
  <div>
    <section class="blogpage-section">
      <div class="container">
        <b-breadcrumb style="font-size: 12px">
          <b-breadcrumb-item class="ahref" to="/berita">
            <b-icon icon="newspaper" scale="1" shift-v="1" aria-hidden="true"></b-icon>
            Berita
          </b-breadcrumb-item>

          <b-breadcrumb-item active>{{
            post?.title?.rendered.slice(0, 30) + "..."
          }}</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="row mt-3">
          <!-- Main Content -->
          <div class="col-lg-8 col-md-7">
            <!-- Loading Spinner -->
            <div v-if="loading" class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <!-- Error Message -->
            <div v-else-if="hasError" class="row">
              <div class="py-5 text-center">
                <div class="container">
                  <div class="col-lg-12">
                    <h3 class="text-dqm">Mohon maaf!</h3>
                    <span>Terjadi kesalahan saat memuat data.</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- No Data Message -->
            <div v-else-if="!loading && !post" class="row">
              <div class="py-5 text-center">
                <div class="container">
                  <div class="col-lg-12">
                    <h3 class="text-dqm">Mohon maaf!</h3>
                    <span>Data Tidak Tersedia</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Post Content -->
            <div v-else class="single-post-area">
              <div class="contact-info-area">
                <h4 class="lh-sm">{{ post?.title?.rendered }}</h4>

                <p class="post-date mt-2" style="font-size: 12px">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  {{
                    post?.yoast_head_json?.author.charAt(0).toUpperCase() +
                    post?.yoast_head_json?.author.slice(1)
                  }}
                  - {{ formattedDate }}
                </p>
              </div>

              <div class="mt-3 img-berita-detail">
                <p v-html="post?.content?.rendered"></p>
              </div>

              <social-share class="mb-2 mt-2" />
            </div>
          </div>

          <!-- Sidebar -->
          <div class="col-lg-4 col-md-7">
            <div class="position-sticky" style="top: 2rem">
              <side-berita />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SideBerita from "@/components/Berita/SideBerita.vue";

import SocialShare from "@/components/socialshare.vue";

import axios from "axios";

export default {
  components: {
    SideBerita,
    SocialShare,
  },

  metaInfo() {
    return {
      title: this.post?.title?.rendered,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.post?.title?.rendered,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.post?.yoast_head_json?.og_description,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.post?.yoast_head_json?.og_image?.[0]?.url,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
      ],
    };
  },
  data() {
    return {
      post: null,
      loading: true,
      hasError: false,
    };
  },
  computed: {
    postImage() {
      // Extract the first image from the post content
      const imageMatch = this.post?.content?.rendered.match(/<img.*?src="(.*?)"/);
      return imageMatch ? imageMatch[1] : "";
    },
    formattedDate() {
      if (this.post?.date_gmt) {
        // Create a new Date object from the date_gmt
        const date = new Date(this.post.date_gmt);
        // Format the date in Indonesian style
        return date.toLocaleDateString("id-ID", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
      return "";
    },
  },

  watch: {
    post: {
      handler(newPost) {
        if (newPost) {
          this.$nextTick(() => {
            this.$meta().refresh();
          });
        }
      },
      immediate: true,
    },
  },

  async created() {
    // Get the post id from the route params
    const postId = this.$route.params.id;
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BERITAURL}/wordpressapi/wp/v2/posts/${postId}`
      );
      this.post = this.replaceDomain(response.data);
      if (!this.post) {
        // Handle case where post is empty
        this.hasError = true;
      }
    } catch (error) {
      console.error("Error fetching post data:", error);
      this.hasError = true;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    replaceDomain(postData) {
      if (postData && postData.content && postData.content.rendered) {
        // Ganti domain di dalam konten HTML
        postData.content.rendered = postData.content.rendered.replace(
          /admin\.darulquran\.sch\.id/g,
          "api-2-gtw.dq.akses.live/wp-image"
        );
      }

      // Pastikan jika ada meta yang juga perlu diganti domain-nya
      if (postData.yoast_head_json && postData.yoast_head_json.author) {
        postData.yoast_head_json.author = postData.yoast_head_json.author.replace(
          /admin\.darulquran\.sch\.id/g,
          "api-2-gtw.dq.akses.live/wp-image"
        );
      }

      return postData;
    },
  },
};
</script>

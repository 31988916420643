<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area footer-dqm">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="footer-copyright d-flex align-items-center justify-content-between pt-35"
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <img
                    src="https://izi.or.id/wp-content/uploads/2022/03/logo.png"
                    style="height: 50px"
                  />
                </li>
                <li>
                  <img src="@/assets/images/logo/footer-logo.png" style="height: 50px" />
                </li>
              </ul>
            </div>

            <div class="copyright-text">
              <p>&copy; {{ new Date().getFullYear() }}. DQM Peduli</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="back-to-top back-to-top-2">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>

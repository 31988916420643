<template>
  <section id="service" class="appie-services-2-area pb-100 pt-90 pb-100">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-lg-12">
          <div class="appie-section-title">
            <h3 class="appie-title">Metode Transfer Bank</h3>
            <p>
              Metode Transfer Bank via ATM dan Internet Banking atas nama<b> DQ Peduli</b> yang
              tersedia di bawah ini melalui Rekening BSI
            </p>
          </div>
        </div>
      </div>
      <div class="row">

        <!-- //rekening -->
 <div v-for="rekening in rekenings" :key="rekening.id" class="col-lg-6">
      <div class="appie-blog-item-3 mt-30">
        <div class="thumb">
          <img :src="rekening.logo" alt="Logo Bank" />
        </div>
        <div class="content">
          <h4 class="title">
            <a href="#" @click.prevent="copyToClipboard(rekening.number)" title="Klik Untuk Salin">{{ rekening.number }}</a>
          </h4>
          <div class="meta-item">
            <ul>
              <li><i class="fal fa-file"></i> {{ rekening.description }}</li>
            </ul>
          </div>
        </div>
      </div>
      </div>
<!-- Snackbar -->
    <div v-if="showSnackbar" class="snackbar">
      {{ snackbarMessage }}
    </div>
        
       
        <div class="col-lg-12">
          <div class="blog-btn text-center mt-60"></div>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row align-items-end">
        <div class="col-lg-12">
          <div class="appie-section-title">
            <h3 class="appie-title">Pembayaran via QRIS</h3>
            <p>
              Metode pembayaran melalui QRIS dapat melalui kode QRIS yang tersedia di
              bawah ini
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="service-details-sidebar mr-50">
            <div class="service-download-widget mb-2">
              <a href="#"
                ><i class="fal fa-download"></i
                ><span>Bagaimana cara melakukan pembayaran menggunakan QRIS?</span></a
              >
            </div>
            <div class="service-category-widget">
              <ul>
                <li>
                  <i class="fal fa-home"></i>Pilih metode pembayaran di halaman proses
                  pembayaran, lalu pilih “Uang elektronik” kemudian “QRIS” dan klik “Bayar
                  sekarang”.
                </li>
                <li>
                  <i class="fal fa-tag"></i>Selanjutnya kamu akan diarahkan ke halaman
                  konfirmasi nominal transaksi.
                </li>
                <li>
                  <i class="fal fa-user-friends"></i>Cek kembali nominal transaksi kamu,
                  lalu scan QR Code menggunakan aplikasi uang elektronik, dompet
                  elektronik, atau mobile banking yang mendukung QRIS.
                </li>
                <li>
                  <i class="fal fa-bookmark"></i>Apabila kamu menggunakan handphone untuk
                  melakukan transaksi, kamu dapat melakukan screencapture dan upload
                  barcode QRIS di aplikasi yang mendukung pembayaran QRIS.
                </li>
                <li>
                  <i class="fal fa-globe"></i>Masukan PIN untuk melanjutkan transaksi.
                </li>
              </ul>
            </div>

            <div class="service-download-widget">
              <a
                href="https://wa.me/6281212427687?text=Assalamu%E2%80%99alaikum%20Warahmatullahi%20Wabarakatuh%2C%20hallo%20DQM%20Peduli%20%5Bwebsite"
                ><b-icon
                  icon="whatsapp"
                  variant="dark"
                  aria-hidden="true"
                  class="me-3"
                ></b-icon
                ><span
                  >Pembayaran berhasil dan Konfirmasi dengan WA (6281212427687 )</span
                ></a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <img src="@/assets/images/rekening/qris.jpeg" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      rekenings: [
        { id: 1, number: '839038 121710 1420', description: 'Rekening zakat', logo: require('@/assets/images/rekening/bsi.png') },
        { id: 2, number: '839038 121720 1420', description: 'Rekening Infaq', logo: require('@/assets/images/rekening/bsi.png') },
        { id: 3, number: '839038 121720 1611', description: 'Rekening Beasiswa Pendidikan Yatim', logo: require('@/assets/images/rekening/bsi.png') },
        { id: 4, number: '839038 121720 1612', description: 'Rekening Peduli Guru Ngaji', logo: require('@/assets/images/rekening/bsi.png') },
        { id: 5, number: '839038 121720 1613', description: 'Rekening Tebar Qurban', logo: require('@/assets/images/rekening/bsi.png') },
        { id: 6, number: '839038 121720 1614', description: 'Rekening Beasiswa Penghafal Quran', logo: require('@/assets/images/rekening/bsi.png') },
        { id: 7, number: '839038 121720 1615', description: 'Rekening Bantuan Pendidikan Masyarakat', logo: require('@/assets/images/rekening/bsi.png') },
      
      ],
      showSnackbar: false,
      snackbarMessage: ''
    };
  },
  methods: {
    copyToClipboard(text) {
      if (text === '#') {
        this.showSnackbarMessage('Nomor rekening belum tersedia');
        return;
      }
      
      navigator.clipboard.writeText(text).then(() => {
        this.showSnackbarMessage('Nomor rekening telah disalin!');
      }).catch(err => {
        console.error('Gagal menyalin teks:', err);
      });
    },
    showSnackbarMessage(message) {
      this.snackbarMessage = message;
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3000);
    }
  }
};
</script>

<style>
.snackbar {
  position: fixed;
  bottom: 0px;
  left: 50%;
  width: 320px;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  text-align: center;
  padding: 12px 12px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
}
</style>

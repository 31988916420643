<template>
  <div>
    <section class="blogpage-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <all-campaign />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AllCampaign from "./AllCampaign.vue";
export default {
  components: {
    AllCampaign,
  },
  metaInfo() {
    return {
      title: "Campaign",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: "Campaign",
        },
      ],
    };
  },
  data() {
    return {};
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

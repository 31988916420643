<template>
  <div class="appie-error-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 pt-95 pb-105">
          <div class="appie-error-content text-center">
            <span>Maaf!</span>
            <h4 class="title">Halaman Dalam Pengembangan</h4>
            <p>
              The page you're looking for isn't available. Try with another page or use
              the go home button below
            </p>
            <router-link to="/" class="main-btn ml-30">Home</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <section class="appie-blog-3-area pt-40 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">Laporan</h3>
            <p>Laporan DQM Peduli</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-40">
          <div class="appie-blog-item-3" style="block-size: 100%">
            <div class="thumb"><img src="/" alt="" /></div>
            <div class="content">
              <h5 class="title">
                <a href="/news" class="">How to Improve Your App Store Position</a>
              </h5>
              <div class="meta-item">
                <ul>
                  <li><i class="fal fa-clock"></i> July 14, 2022</li>
                  <li><i class="fal fa-comments"></i> July 14, 2022</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-40">
          <div class="appie-blog-item-3" style="block-size: 100%">
            <div class="thumb"><img src="/" alt="" /></div>
            <div class="content">
              <h5 class="title">
                <a href="/news" class="">Introducing New App Design for our iOS App</a>
              </h5>
              <div class="meta-item">
                <ul>
                  <li><i class="fal fa-clock"></i> July 14, 2022</li>
                  <li><i class="fal fa-comments"></i> July 14, 2022</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-40">
          <div class="appie-blog-item-3" style="block-size: 100%">
            <div class="thumb"><img src="/" alt="" /></div>
            <div class="content">
              <h5 class="title">
                <a href="/news" class="">Engineering job is Becoming More interesting.</a>
              </h5>
              <div class="meta-item">
                <ul>
                  <li><i class="fal fa-clock"></i> July 14, 2022</li>
                  <li><i class="fal fa-comments"></i> July 14, 2022</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-40">
          <div class="appie-blog-item-3" style="block-size: 100%">
            <div class="thumb"><img src="/img/blog-7.a5e270d8.jpg" alt="" /></div>
            <div class="content">
              <h5 class="title">
                <a href="/news" class="">20 Myths About Mobile Applications</a>
              </h5>
              <div class="meta-item">
                <ul>
                  <li><i class="fal fa-clock"></i> July 14, 2022</li>
                  <li><i class="fal fa-comments"></i> July 14, 2022</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="blog-btn text-center mt-60">
            <a href="#" class="main-btn">Load <i class="fal fa-arrow-down"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
export default {};
</script>

<style></style>
